import React, { useRef, useState } from "react";
import styles from "../../stylesheets/Register.module.css";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";

const Register = () => {
  // refs
  let password = useRef(null);
  let password2 = useRef(null);

  let email = useRef(null);

  let [errorMEssage, setErrorMessage] = useState(null);
  let [registerLoading, setRegisterLoading] = useState(false);

  let navigate = useNavigate();
  let registerUser = async (e) => {
    e.preventDefault();
    setRegisterLoading(true);
    let response = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/api/register/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: e.target.email.value,
          first_name: e.target.firstName.value,
          last_name: e.target.lastName.value,
          password: e.target.password.value,
          password2: e.target.password2.value,
        }),
      }
    );

    let data = await response.json();

    if (response.status === 200) {
      navigate("/login");
    } else {
      // clear previous borders
      password.current.style.border = null;
      password2.current.style.border = null;
      email.current.style.border = null;
      setRegisterLoading(false);

      console.log(data);
      if (data.hasOwnProperty("password")) {
        password.current.style.border = "1px solid red";
        password2.current.style.border = "1px solid red";
        setErrorMessage(data.password[0]);
      }
      if (data.hasOwnProperty("email")) {
        email.current.style.border = "1px solid red";
        setErrorMessage("This email is already in use");
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.form_box}>
        <h1 id="title">Sign Up</h1>
        <form onSubmit={registerUser}>
          <div className={styles.register_input_group} id="inputGroup">
            <div className={styles.input_field}>
              <FontAwesomeIcon className={styles.icon} icon={faUser} />
              <input
                type="text"
                placeholder="First Name"
                name="firstName"
                required
              />
            </div>
            <div className={styles.input_field}>
              <FontAwesomeIcon className={styles.icon} icon={faUser} />
              <input
                type="text"
                placeholder="Last Name"
                name="lastName"
                required
              />
            </div>

            <div className={styles.input_field} ref={email}>
              <FontAwesomeIcon className={styles.icon} icon={faEnvelope} />
              <input type="email" placeholder="Email" name="email" required />
            </div>

            <div className={styles.input_field} ref={password}>
              <FontAwesomeIcon className={styles.icon} icon={faLock} />
              <input
                type="password"
                placeholder="Password"
                id="password"
                name="password"
                required
              />
            </div>
            <div className={styles.input_field} ref={password2}>
              <FontAwesomeIcon className={styles.icon} icon={faLock} />
              <input
                type="password"
                placeholder="Confirm Password"
                name="password2"
                required
              />
            </div>

            <p className="errorMsg">{errorMEssage && `*${errorMEssage}`}</p>
            <br />
          </div>
          <div className={styles.btn_field}>
            {!registerLoading ? (
              <input type="submit" value="Register" />
            ) : (
              <CircularProgress />
            )}
          </div>

          <div className={`${styles.link_field} ${styles.sign_up_link}`}>
            <p>
              Already have an account?
              <Link to="/login">Sign In Here!</Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
