import { createContext, useState, useEffect, useRef, useCallback } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let [user, setUser] = useState(null);

  let [accessToken, setAccessToken] = useState(null);

  let navigate = useNavigate();
  let [loading, setLoading] = useState(true);

  let ref = useRef(true);

  //   login api
  let loginUser = async (e) => {
    e.preventDefault();

    let response = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/api/token/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          email: e.target.email.value,
          password: e.target.password.value,
        }),
      }
    );

    let data = await response.json();
    if (response.status === 200) {
      console.log(data);
      setAccessToken(data.access);
      setUser(jwt_decode(data.access));
      //   navigate to home page
      navigate("/");
    } else {
      return "email and password combination is invalid";
    }
  };

  let logoutUser = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/api/logout/`,
      {
        method: "POST",
        credentials: "include",
      }
    );

    if (response.status === 200) {
      setAccessToken(null);
      setUser(null);
      navigate("/login");
    } else if (response.status === 208) {
      console.log("user already logged out");
      setAccessToken(null);
      setUser(null);
      navigate("/login");
    } else {
      setAccessToken(null);
      setUser(null);
      navigate("/login");
    }
  };

  let updateTokens = useCallback(async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/api/token/refresh/`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    let data = await response.json();
    if (response.status === 200) {
      setAccessToken(data.access_token);
      setUser(jwt_decode(data.access_token));
    } else {
      setAccessToken(null);
      setUser(null);
      navigate("/login");
    }

    if (loading) {
      setLoading(false);
    }
  }, [loading, navigate]);

  let contextData = {
    // Variable
    user: user,
    accessToken: accessToken,
    // refreshToken: refreshToken,
    loading: loading,

    // Functions
    loginUser: loginUser,
    logoutUser: logoutUser,
    updateTokens: updateTokens,
  };

  useEffect(() => {
    if (ref.current) {
      updateTokens();
      ref.current = false;
    }

    let fourMinutes = 1000 * 60 * 4;
    let interval = setInterval(() => {
      if (user) {
        updateTokens();
      }
    }, fourMinutes);
    return () => clearInterval(interval);
  }, [user, updateTokens]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
