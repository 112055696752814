import React, { useEffect, useState, useContext, useCallback } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AlertComponent from "../../../components/AlertComponent";
import CircularProgress from "@mui/material/CircularProgress";

const RoomHome = () => {
  let { roomUUID } = useParams();
  let { accessToken } = useContext(AuthContext);
  let [userIsMember, setUserIsMember] = useState(null);
  let [fetchedData, setFetchedData] = useState(false);
  let [teams, setTeams] = useState([]);
  let [userTeam, setUserTeam] = useState(null);
  let [loader, setLoader] = useState(false);

  let navigate = useNavigate();

  let [createOpen, setCreateOpen] = useState(false);
  let [JoinOpen, setJoinOpen] = useState(false);
  let [key, setKey] = useState("");
  let [teamName, setTeamName] = useState("");

  // alert states
  let [alertText, setAlertText] = useState("");
  let [alertType, setAlertType] = useState("");
  let [alertShow, setAlertShow] = useState(false);

  let openCreateModal = () => {
    setCreateOpen(true);
  };

  let closeCreateModal = () => {
    setCreateOpen(false);
  };

  let openJoinModal = () => {
    setJoinOpen(true);
  };
  let closeJoinModal = () => {
    setJoinOpen(false);
  };

  // API alls
  let getTeams = useCallback(async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/api/teams-in-room/${roomUUID}/`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    let data = await response.json();
    if (response.status === 200) {
      let teamsList = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i].is_member === true) {
          setUserTeam(data[i]);
        } else {
          teamsList.push(data[i]);
        }
      }
      setTeams(teamsList);
      setUserIsMember(true);
    } else if (response.status === 409) {
      setUserIsMember(false);
    } else {
      console.log(response.status);
      setUserIsMember(false);
    }
  }, [accessToken, roomUUID]);

  let createTeam = async () => {
    setLoader(true);
    let response = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/api/team/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ room: roomUUID, name: teamName }),
      }
    );

    let data = await response.json();

    if (response.status === 201) {
      navigate(`/room/${roomUUID}/${data.uuid}/`);
      setLoader(false);
    } else {
      setLoader(false);
      setAlertText("An error has occured");
      setAlertType("error");
      setAlertShow(true);
      closeCreateModal();
    }
  };

  let joinTeam = async (key) => {
    setLoader(true);
    let response = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/api/team/${key}/`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    let data = await response.json();

    if (response.status === 200) {
      navigate(`/room/${roomUUID}/${data.uuid}/`);
      setLoader(false);
    } else {
      setLoader(false);
      if (response.status === 409) {
        setAlertText(data.detail);
        setAlertType("error");
        setAlertShow(true);
        closeJoinModal();
      } else if (response.status === 404) {
        setAlertText("Team not found");
        setAlertType("error");
        setAlertShow(true);
        closeJoinModal();
      } else {
        setAlertText("An error has occured");
        setAlertType("error");
        setAlertShow(true);
        closeJoinModal();
      }
    }
  };
  // use Effect
  useEffect(() => {
    if (!fetchedData) {
      setFetchedData(true);
      getTeams();
    }
  }, [getTeams, fetchedData]);

  // return
  return userIsMember ? (
    <div className="wrapper">
      {alertShow && (
        <AlertComponent
          text={alertText}
          type={alertType}
          setAlertShow={setAlertShow}
        />
      )}
      <div
        className={
          teams.length !== 0 || userTeam !== null ? undefined : "center-btn"
        }
      >
        <div className="btn-wrapper">
          <Button
            variant="contained"
            className="btn-center"
            onClick={openJoinModal}
          >
            Join Team
          </Button>
          <Dialog open={JoinOpen} onClose={closeJoinModal}>
            <DialogTitle>Join Team</DialogTitle>
            <DialogContent>
              <TextField
                sx={{ mb: 3 }}
                autoFocus
                margin="dense"
                id="name"
                label="Team Key"
                type="text"
                fullWidth
                variant="standard"
                value={key}
                onChange={(e) => {
                  setKey(e.target.value);
                }}
              />
            </DialogContent>
            <DialogActions>
              {!loader && <Button onClick={closeJoinModal}>Cancel</Button>}
              {!loader ? (
                <Button
                  onClick={() => {
                    if (key !== "") {
                      joinTeam(key);
                    }
                  }}
                >
                  Join
                </Button>
              ) : (
                <CircularProgress />
              )}
            </DialogActions>
          </Dialog>
        </div>
        {/* Create room modal */}
        <div className="btn-wrapper">
          <Button
            variant="contained"
            className="btn-center"
            onClick={openCreateModal}
          >
            Create Team
          </Button>
          <Dialog open={createOpen} onClose={closeCreateModal}>
            <DialogTitle>Create Team</DialogTitle>
            <DialogContent>
              <TextField
                sx={{ mb: 3 }}
                autoFocus
                margin="dense"
                id="name"
                label="Team Name"
                type="text"
                fullWidth
                variant="standard"
                value={teamName}
                onChange={(e) => {
                  setTeamName(e.target.value);
                }}
              />
            </DialogContent>
            <DialogActions>
              {!loader && <Button onClick={closeCreateModal}>Cancel</Button>}
              {!loader ? (
                <Button
                  onClick={() => {
                    createTeam();
                  }}
                >
                  Create
                </Button>
              ) : (
                <CircularProgress />
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>
      {userTeam !== null && (
        <div className="user_team_wrapper">
          <h1 style={{ textAlign: "center" }}>My Team</h1>
          <div className="room_team">
            <h3 style={{ textAlign: "center" }}>{userTeam.name}</h3>
            <p>Completed Challenges: {userTeam.completed_challenges}</p>
            <p>Pending Challeneges: {userTeam.incomplete_challenges}</p>
            <p>Total Points: {userTeam.points}</p>
            <p>Members: {userTeam.members}</p>
            <p>Team Key: {userTeam.key}</p>
            <br />
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/room/${roomUUID}/${userTeam.uuid}/`);
              }}
            >
              See Challenges
            </Button>
          </div>
        </div>
      )}
      {teams.length !== 0 && (
        <div className="room_teams_wrapper">
          <h1 style={{ textAlign: "center" }}>Room Teams</h1>
          {teams.map((team, index) => (
            <div key={index} className="room_team">
              <h3 style={{ textAlign: "center" }}>{team.name}</h3>
              <p>Completed Challenges: {team.completed_challenges}</p>
              <p>Pending Challeneges: {team.incomplete_challenges}</p>
              <p>Members: {team.members}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  ) : userIsMember === false ? (
    <Navigate to="/" />
  ) : (
    <div></div>
  );

  // if (userIsMember) {
  //   return (
  //     <div>
  //       <h1>Hello</h1>
  //     </div>
  //   );
  // } else if (userIsMember === false) {
  //   return <Navigate to="/" />;
  // } else {
  //   return <div></div>;
  // }
};

export default RoomHome;
