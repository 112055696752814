import React, { useEffect, useContext, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import AlertComponent from "../../components/AlertComponent";
import CircularProgress from "@mui/material/CircularProgress";

const Home = () => {
  let { accessToken } = useContext(AuthContext);
  let [joinOpen, setJoinOpen] = useState(false);
  let [createOpen, setCreateOpen] = useState(false);
  let [key, setKey] = useState("");
  let [roomName, setRoomName] = useState("");
  let [challengeSets, setChallengeSets] = useState([]);
  let [selectedChallengeSet, setSelectedChallengeSet] = useState("");
  let [fetchedData, setFetchedData] = useState(false);
  let [rooms, setRooms] = useState([]);
  let [alertText, setAlertText] = useState("");
  let [alertShow, setAlertShow] = useState(false);
  let [alertType, setAlertType] = useState("");
  let [loader, setLoader] = useState(false);

  let navigate = useNavigate();

  let openJoinModal = () => {
    setJoinOpen(true);
  };

  let closeJoinModal = () => {
    setJoinOpen(false);
  };

  let openCreateModal = () => {
    setCreateOpen(true);
  };

  let closeCreateModal = () => {
    setCreateOpen(false);
  };

  // Join room API call
  let joinRoom = async (key) => {
    setLoader(true);
    let response = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/api/room/${key}/`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    let data = await response.json();
    if (response.status === 200) {
      navigate(`/room/${data.uuid}`);
      setLoader(false);
    } else {
      setLoader(false);
      if (response.status === 404) {
        setAlertText("Room Not Found");
        setAlertType("error");
        setAlertShow(true);
        closeJoinModal();
      } else if (response.status === 409) {
        setAlertText(data.detail);
        setAlertType("error");
        setAlertShow(true);
        closeJoinModal();
      } else {
        setAlertText(data.detail);
        setAlertType("error");
        setAlertShow(true);
        closeJoinModal();
      }
    }
  };

  // Create room api call
  let createRoom = async (name, challengeSet) => {
    setLoader(true);
    let response = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/api/room/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ name: name, challenge_set: challengeSet }),
      }
    );

    let data = await response.json();

    if (response.status === 201) {
      navigate(`/room/${data.uuid}`);
      setLoader(false);
    } else {
      setAlertText("An error has occured");
      setLoader(false);
      setAlertType("error");
      setAlertShow(true);
      closeJoinModal();
    }
  };

  // Get rooms api call
  let getRooms = useCallback(async () => {
    console.log("access token is");
    console.log(accessToken);

    let response = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/api/room/`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    let data = await response.json();

    if (response.status === 200) {
      let roomsList = [];
      for (let i = 0; i < data.length; i++) {
        roomsList.push(data[i]);
      }
      setRooms(roomsList);
    }
  }, [accessToken]);

  // get challengeSet api call
  let getChallengeSets = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/api/challenge-set/`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    let data = await response.json();
    if (response.status === 200) {
      let setList = [];
      for (let i = 0; i < data.length; i++) {
        setList.push(data[i]);
      }

      setChallengeSets(setList);
    }
  };

  useEffect(() => {
    // only call the getRooms() once loading is set to false in authcontext
    if (!fetchedData) {
      getRooms();
      getChallengeSets();
      setFetchedData(true);
    }
  }, [getRooms, fetchedData]);

  return (
    <div className="wrapper">
      {alertShow && (
        <AlertComponent
          text={alertText}
          type={alertType}
          setAlertShow={setAlertShow}
        />
      )}

      <div className={rooms.length === 0 ? "center-btn" : undefined}>
        {/* Join room modal */}
        <div className="btn-wrapper">
          <Button
            variant="contained"
            onClick={openJoinModal}
            className="btn-center"
          >
            Join Room
          </Button>
          <Dialog open={joinOpen} onClose={closeJoinModal}>
            <DialogTitle>Join Room</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Room Key"
                type="text"
                fullWidth
                variant="standard"
                value={key}
                onChange={(e) => {
                  setKey(e.target.value);
                }}
              />
            </DialogContent>
            <DialogActions>
              {!loader && <Button onClick={closeJoinModal}>Cancel</Button>}
              {!loader ? (
                <Button
                  onClick={() => {
                    if (key !== "") {
                      joinRoom(key);
                    }
                  }}
                >
                  Join
                </Button>
              ) : (
                <CircularProgress />
              )}
            </DialogActions>
          </Dialog>
        </div>
        {/* Create room modal */}
        <div className="btn-wrapper">
          <Button
            variant="contained"
            onClick={openCreateModal}
            className="btn-center"
          >
            Create Room
          </Button>
          <Dialog open={createOpen} onClose={closeCreateModal}>
            <DialogTitle>Create Room</DialogTitle>
            <DialogContent>
              <TextField
                sx={{ mb: 3 }}
                autoFocus
                margin="dense"
                id="name"
                label="Room Name"
                type="text"
                fullWidth
                variant="standard"
                value={roomName}
                onChange={(e) => {
                  setRoomName(e.target.value);
                }}
              />

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Challenge Set
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedChallengeSet}
                  label="Challenge Set"
                  onChange={(e) => {
                    setSelectedChallengeSet(e.target.value);
                  }}
                >
                  {challengeSets.map((challengeSet, index) => (
                    <MenuItem value={challengeSet.uuid} key={index}>
                      {challengeSet.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              {!loader && <Button onClick={closeCreateModal}>Cancel</Button>}
              {!loader ? (
                <Button
                  onClick={() => {
                    if (roomName !== "" && selectedChallengeSet !== "") {
                      createRoom(roomName, selectedChallengeSet);
                    }
                  }}
                >
                  Create
                </Button>
              ) : (
                <CircularProgress />
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>

      <div>
        {rooms.length !== 0 ? (
          <h1 style={{ textAlign: "center", marginTop: "50px" }}>My Rooms</h1>
        ) : null}
        <div className="room-wrapper">
          {rooms.map((room, index) => (
            <div key={index} className="room">
              <h3> {room.name}</h3>
              <p>
                My Team:{" "}
                {room.hasOwnProperty("user_team")
                  ? room.user_team.name
                  : "Not a member of a team"}
              </p>
              <p>room key: {room.key}</p>
              <Button
                variant="contained"
                onClick={() => {
                  if (room.hasOwnProperty("user_team")) {
                    navigate(`/room/${room.uuid}/${room.user_team.id}/`);
                  } else {
                    navigate(`/room/${room.uuid}/`);
                  }
                }}
              >
                Go to room
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
