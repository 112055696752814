import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import NavbarComponent from "../../components/navbar/NavbarComponent";

const AuthenticatedRoute = () => {
  let { user } = useContext(AuthContext);
  return user ? (
    <>
      <NavbarComponent />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default AuthenticatedRoute;
