import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

const NavbarComponent = () => {
  let { logoutUser } = useContext(AuthContext);
  return (
    <nav className="nav">
      <Link to="/" className="logo">
        The Passionate Ones
      </Link>
      <h4 className="logout" onClick={logoutUser}>
        Logout
      </h4>
    </nav>
  );
};

export default NavbarComponent;
