import React, { useState, useEffect, useCallback, useContext } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AlertComponent from "../../../components/AlertComponent";
import CircularProgress from "@mui/material/CircularProgress";

const Team = () => {
  let { accessToken } = useContext(AuthContext);
  let [userIsMember, setUserIsMember] = useState(null);
  let [dataFetched, setDataFetched] = useState(false);
  let { roomUUID, teamUUID } = useParams();
  let [completesTrue, setCompletesTrue] = useState([]);
  let [completesFalse, setCompletesFalse] = useState([]);
  let [uploadOpen, setUploadedOpen] = useState(false);
  let [selectedChallenege, setSelectedChalleneg] = useState(null);
  let [file, setFile] = useState(null);
  let [loader, setLoader] = useState(false);
  let navigate = useNavigate();

  // alert states
  let [alertText, setAlertText] = useState("");
  let [alertType, setAlertType] = useState("");
  let [alertShow, setAlertShow] = useState(false);

  let challengeCompletes = useCallback(async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/api/completes/${teamUUID}/`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    let data = await response.json();

    if (response.status === 200) {
      let completesTrueList = [];
      let completesFalseList = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].completed === true) {
          completesTrueList.push(data[i]);
        } else {
          completesFalseList.push(data[i]);
        }
      }
      setCompletesTrue(completesTrueList);
      setCompletesFalse(completesFalseList);
    }
  }, [accessToken, teamUUID]);
  let userInTeam = useCallback(async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/api/get-current-team/${roomUUID}/`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    let data = await response.json();

    if (response.status === 200) {
      if (data.uuid === teamUUID) {
        setUserIsMember(true);
      } else {
        setUserIsMember(false);
      }
    } else if (response.status === 409) {
      setUserIsMember(false);
    } else {
      console.log(response.status);
      setUserIsMember(false);
    }
  }, [accessToken, roomUUID, teamUUID]);

  // Upload Entry api
  let uploadEntry = async (formData) => {
    setLoader(true);
    let response = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/api/post-challenge-entry/`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      }
    );

    let data = await response.json();

    if (response.status === 201) {
      setLoader(false);
      console.log("Yes");
      challengeCompletes();
      closeUploadModal();
    } else {
      setLoader(false);
      setAlertText(data.detail);
      setAlertShow(true);
      setAlertType("error");
      closeUploadModal();
    }
  };

  let closeUploadModal = () => {
    setUploadedOpen(false);
  };

  useEffect(() => {
    if (!dataFetched) {
      userInTeam();
      challengeCompletes();
      setDataFetched(true);
    }
  }, [teamUUID, userInTeam, challengeCompletes, dataFetched]);
  return userIsMember ? (
    <div className="wrapper">
      {alertShow && (
        <AlertComponent
          text={alertText}
          type={alertType}
          setAlertShow={setAlertShow}
        />
      )}
      <Button
        className="center-btn"
        variant="contained"
        onClick={() => {
          navigate(`/room/${roomUUID}/`);
        }}
      >
        Go to Room Lobby
      </Button>
      <div className="incomplete_wrapper">
        <h1 style={{ textAlign: "center" }}>Incomplete Challenges</h1>
        {completesFalse.map((challenge, index) => (
          <div key={index} className="incomplete-challenge">
            <div className="challenge-container">
              <h4>
                {challenge.challenge.name} - {challenge.challenge.score} points
              </h4>
            </div>

            <div className="btn-container">
              <Button
                variant="contained"
                onClick={() => {
                  setSelectedChalleneg(challenge.challenge.uuid);
                  setUploadedOpen(true);
                }}
              >
                Upload
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div className="complete_wrapper">
        <h1 style={{ textAlign: "center" }}>Completed Challenges</h1>
        {completesTrue.map((challenge, index) => (
          <div key={index} className="complete-challenge">
            <h4>
              {challenge.challenge.name} - {challenge.challenge.score} points
            </h4>
          </div>
        ))}
      </div>

      {/* Dialogue */}
      <Dialog open={uploadOpen} onClose={closeUploadModal}>
        <DialogTitle>Upload Challenge Entry</DialogTitle>
        <DialogContent>
          <form>
            <input
              type="file"
              accept="video/mp4, image/jpeg, image/png"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
          </form>
          <br />
          <small>Accepted Media Types: MP4, JPEG, PNG, JPG.</small>
          <br />
          <small>
            If using IPhone, make sure you switch video settings to mp4 to
            ensure successful upload
          </small>
        </DialogContent>
        <DialogActions>
          {!loader && <Button onClick={closeUploadModal}>Cancel</Button>}
          {!loader ? (
            <Button
              onClick={() => {
                if (file !== null) {
                  let formData = new FormData();
                  formData.append("media", file);
                  formData.append("team", teamUUID);
                  formData.append("challenge", selectedChallenege);

                  uploadEntry(formData);
                }
              }}
            >
              Upload
            </Button>
          ) : (
            <CircularProgress />
          )}
        </DialogActions>
      </Dialog>
    </div>
  ) : userIsMember === false ? (
    <Navigate to={`/room/${roomUUID}`} />
  ) : (
    <div></div>
  );
};

export default Team;
