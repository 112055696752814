import React, { useContext, useState } from "react";
import styles from "../../stylesheets/Login.module.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../context/AuthContext";
import CircularProgress from "@mui/material/CircularProgress";

const Login = () => {
  let { loginUser } = useContext(AuthContext);
  let [loginLoading, setLoginLoading] = useState(false);
  let [errorMessage, setErrorMessage] = useState(null);
  return (
    <div className={styles.container}>
      <div className={styles.form_box}>
        <h1 id="title">Sign In </h1>
        <form
          onSubmit={async (e) => {
            setLoginLoading(true);
            let response = await loginUser(e);
            setErrorMessage(response);
            if (response === "email and password combination is invalid") {
              setLoginLoading(false);
            }
          }}
        >
          <div className={styles.login_input_group} id="inputGroup">
            <div className={styles.input_field}>
              <FontAwesomeIcon className={styles.icon} icon={faEnvelope} />
              <input
                type="email"
                placeholder="Email"
                id="email"
                name="email"
                required
              />
            </div>

            <div className={styles.input_field}>
              <FontAwesomeIcon className={styles.icon} icon={faLock} />
              <input
                type="password"
                placeholder="Password"
                id="password"
                name="password"
                required
              />
            </div>
            <p className="errorMsg">{errorMessage && `*${errorMessage}`}</p>
            <br />
          </div>
          <div className={styles.btn_field}>
            {!loginLoading ? (
              <input type="submit" id="signInBtn" value="Login" />
            ) : (
              <CircularProgress />
            )}
          </div>
          <p className={styles.forgot_password}>
            Forgot password? <Link to="#">Click Here!</Link>
          </p>
          <div className={`${styles.link_field} ${styles.sign_up_link}`}>
            <p>
              Dont have an Account?
              <Link to="/register">Sign Up Here!</Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
