import React from "react";

import Alert from "@mui/material/Alert";
const AlertComponent = ({ text, type, setAlertShow }) => {
  return (
    <div className="alert_wrapper">
      <Alert
        severity={type}
        onClose={() => {
          setAlertShow(false);
        }}
      >
        {text}
      </Alert>
    </div>
  );
};

export default AlertComponent;
