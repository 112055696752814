import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./Pages/Login/index";
import AuthenticatedRoute from "./utils/routes/AuthenticatedRoute";
import { AuthProvider } from "./context/AuthContext";
import Home from "./Pages/Home";
import Register from "./Pages/Register";
import RoomHome from "./Pages/RoomPages/RoomHome/index";
import Team from "./Pages/RoomPages/Team/index";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" exact Component={Login}></Route>
          <Route path="/register" exact Component={Register}></Route>

          {/* Wrapped authenticated routes */}
          <Route Component={AuthenticatedRoute}>
            <Route path="/" exact Component={Home}></Route>
            <Route path="room/:roomUUID" Component={RoomHome}></Route>
            <Route path="room/:roomUUID/:teamUUID" Component={Team}></Route>
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
